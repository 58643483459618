var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.content.type === 'text')?_c('text-message',{attrs:{"data":_vm.content.text}}):_vm._e(),_vm._v(" "),(_vm.content.type === 'sticker')?_c('sticker-message',{attrs:{"data":_vm.content.stickerId}}):_vm._e(),_vm._v(" "),(['image', 'video', 'audio'].includes(_vm.content.type))?_c('media-message',{attrs:{"data":_vm.content}}):_vm._e(),_vm._v(" "),(
      _vm.content.type === _vm.MessageType.Template &&
      _vm.content.template &&
      _vm.content.template.type === _vm.TemplateMessageType.Buttons
    )?_c('buttons-message',{attrs:{"data":_vm.content.template}}):_vm._e(),_vm._v(" "),(
      _vm.content.type === _vm.MessageType.Template &&
      _vm.content.template &&
      _vm.content.template.type === _vm.TemplateMessageType.Confirm
    )?_c('confirm-message',{attrs:{"data":_vm.content.template}}):_vm._e(),_vm._v(" "),(
      _vm.content.type === _vm.MessageType.Template &&
      _vm.content.template &&
      _vm.content.template.type === _vm.TemplateMessageType.Carousel
    )?_c('carousel-message',{attrs:{"data":_vm.content.template.columns}}):_vm._e(),_vm._v(" "),(
      _vm.content.type === _vm.MessageType.Template &&
      _vm.content.template &&
      _vm.content.template.type === _vm.TemplateMessageType.ImageCarousel
    )?_c('image-carousel-message',{attrs:{"data":_vm.content.template.columns}}):_vm._e(),_vm._v(" "),(_vm.content.type === _vm.MessageType.Imagemap)?_c('imagemap-message',{attrs:{"baseUrl":_vm.content.baseUrl}}):_vm._e(),_vm._v(" "),(_vm.content.type === _vm.MessageType.Location)?_c('location-message',{attrs:{"data":_vm.content}}):_vm._e(),_vm._v(" "),(_vm.content.type === _vm.MessageType.Flex)?_c('flex-message',{attrs:{"html":_vm.htmlContent}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }