var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validate = ref.validate;
var invalid = ref.invalid;
return [_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header left-border"},[_c('h3',{staticClass:"card-title"},[_vm._v("クライアント情報")])]),_vm._v(" "),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-xl-3"},[_vm._v("クライアント名"),_c('required-mark')],1),_vm._v(" "),_c('div',{staticClass:"col-xl-9"},[_c('ValidationProvider',{attrs:{"name":"クライアント名","rules":"required|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.clientFormData.name),expression:"clientFormData.name",modifiers:{"trim":true}}],staticClass:"form-control",attrs:{"type":"text","name":"client[name]","placeholder":"入力してください","maxlength":"256"},domProps:{"value":(_vm.clientFormData.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.clientFormData, "name", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_vm._v(" "),_c('span',{staticClass:"error-explanation"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_vm._v(" "),_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-xl-3"},[_vm._v("住所"),_c('required-mark')],1),_vm._v(" "),_c('div',{staticClass:"col-xl-9"},[_c('ValidationProvider',{attrs:{"name":"住所","rules":"required|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.clientFormData.address),expression:"clientFormData.address",modifiers:{"trim":true}}],staticClass:"form-control",attrs:{"type":"text","name":"client[address]","placeholder":"入力してください","maxlength":"256"},domProps:{"value":(_vm.clientFormData.address)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.clientFormData, "address", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_vm._v(" "),_c('span',{staticClass:"error-explanation"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_vm._v(" "),_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-xl-3"},[_vm._v("電話番号"),_c('required-mark')],1),_vm._v(" "),_c('div',{staticClass:"col-xl-9"},[_c('ValidationProvider',{attrs:{"name":"電話番号","rules":"required|numeric|min:10|max:11"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.clientFormData.phone_number),expression:"clientFormData.phone_number",modifiers:{"trim":true}}],staticClass:"form-control",attrs:{"type":"text","name":"client[phone_number]","placeholder":"入力してください","maxlength":"12"},domProps:{"value":(_vm.clientFormData.phone_number)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.clientFormData, "phone_number", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_vm._v(" "),_c('span',{staticClass:"error-explanation"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_vm._v(" "),_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-xl-3"},[_vm._v("有効化")]),_vm._v(" "),_c('div',{staticClass:"col-xl-9"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.clientFormData.status),expression:"clientFormData.status"}],attrs:{"type":"checkbox","id":"enabledCheck","checked":"","data-switch":"info","name":"client[status]","true-value":"active","false-value":"blocked"},domProps:{"checked":Array.isArray(_vm.clientFormData.status)?_vm._i(_vm.clientFormData.status,null)>-1:_vm._q(_vm.clientFormData.status,"active")},on:{"change":function($event){var $$a=_vm.clientFormData.status,$$el=$event.target,$$c=$$el.checked?("active"):("blocked");if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.clientFormData, "status", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.clientFormData, "status", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.clientFormData, "status", $$c)}}}}),_vm._v(" "),_c('label',{attrs:{"for":"enabledCheck","data-on-label":"有","data-off-label":"無"}})])])])]),_vm._v(" "),_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"btn btn-info fw-120",attrs:{"disabled":invalid},on:{"click":function($event){validate().then(_vm.onSubmit)}}},[_vm._v("保存")])])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }