var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"card"},[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validate = ref.validate;
var invalid = ref.invalid;
return [_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-xl-3"},[_vm._v("氏名"),_c('required-mark')],1),_vm._v(" "),_c('div',{staticClass:"col-xl-9"},[_c('ValidationProvider',{attrs:{"name":"氏名","rules":"required|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.accountFormData.name),expression:"accountFormData.name",modifiers:{"trim":true}}],staticClass:"form-control",attrs:{"type":"text","name":"account[name]","placeholder":"入力してください","maxlength":"256"},domProps:{"value":(_vm.accountFormData.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.accountFormData, "name", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_vm._v(" "),_c('span',{staticClass:"error-explanation"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_vm._v(" "),_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-xl-3"},[_vm._v("メールアドレス"),_c('required-mark')],1),_vm._v(" "),_c('div',{staticClass:"col-xl-9"},[_c('ValidationProvider',{attrs:{"name":"メールアドレス","rules":"required|email|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.accountFormData.email),expression:"accountFormData.email",modifiers:{"trim":true}}],staticClass:"form-control",attrs:{"type":"text","name":"account[email]","placeholder":"入力してください","maxlength":"256"},domProps:{"value":(_vm.accountFormData.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.accountFormData, "email", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_vm._v(" "),_c('span',{staticClass:"error-explanation"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_vm._v(" "),_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-xl-3"},[_vm._v("パスワード"),_c('required-mark')],1),_vm._v(" "),_c('div',{staticClass:"col-xl-9"},[_c('ValidationProvider',{attrs:{"name":"パスワード","rules":"required|min:8|max:128","type":"password","vid":"password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.accountFormData.password),expression:"accountFormData.password",modifiers:{"trim":true}}],staticClass:"form-control",attrs:{"type":"text","name":"account[password]","placeholder":"入力してください","maxlength":"129"},domProps:{"value":(_vm.accountFormData.password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.accountFormData, "password", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_vm._v(" "),_c('span',{staticClass:"error-explanation"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_vm._v(" "),_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-xl-3"},[_vm._v("パスワード（確認用）"),_c('required-mark')],1),_vm._v(" "),_c('div',{staticClass:"col-xl-9"},[_c('ValidationProvider',{attrs:{"name":"パスワード（確認用）","rules":"required|min:8|max:128|confirmed:password","type":"password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.accountFormData.password_confirmation),expression:"accountFormData.password_confirmation",modifiers:{"trim":true}}],staticClass:"form-control",attrs:{"type":"text","name":"account[password_confirmation]","placeholder":"入力してください","maxlength":"129"},domProps:{"value":(_vm.accountFormData.password_confirmation)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.accountFormData, "password_confirmation", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_vm._v(" "),_c('span',{staticClass:"error-explanation"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)])]),_vm._v(" "),_c('div',{staticClass:"card-footer row-form-btn d-flex"},[_c('button',{staticClass:"btn btn-info fw-120",attrs:{"type":"submit","disabled":invalid},on:{"click":function($event){validate().then(_vm.onSubmit)}}},[_vm._v("\n          登録\n        ")])])]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }