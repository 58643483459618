var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"card"},[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validate = ref.validate;
var invalid = ref.invalid;
return [_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-xl-3"},[_vm._v("メールアドレス"),_c('required-mark')],1),_vm._v(" "),_c('div',{staticClass:"col-xl-9"},[_c('ValidationProvider',{attrs:{"name":"メールアドレス","rules":"required|email|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.agencyFormData.email),expression:"agencyFormData.email",modifiers:{"trim":true}}],staticClass:"form-control",attrs:{"type":"text","name":"agency[email]","placeholder":"入力してください","maxlength":"256"},domProps:{"value":(_vm.agencyFormData.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.agencyFormData, "email", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_vm._v(" "),_c('span',{staticClass:"error-explanation"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_vm._v(" "),_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-xl-3"},[_vm._v("契約者名"),_c('required-mark')],1),_vm._v(" "),_c('div',{staticClass:"col-xl-9"},[_c('ValidationProvider',{attrs:{"name":"契約者名","rules":"required|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.agencyFormData.name),expression:"agencyFormData.name",modifiers:{"trim":true}}],staticClass:"form-control",attrs:{"type":"text","name":"agency[name]","placeholder":"入力してください","maxlength":"256"},domProps:{"value":(_vm.agencyFormData.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.agencyFormData, "name", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_vm._v(" "),_c('span',{staticClass:"error-explanation"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_vm._v(" "),_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-xl-3"},[_vm._v("住所"),_c('required-mark')],1),_vm._v(" "),_c('div',{staticClass:"col-xl-9"},[_c('ValidationProvider',{attrs:{"name":"住所","rules":"required|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.agencyFormData.address),expression:"agencyFormData.address",modifiers:{"trim":true}}],staticClass:"form-control",attrs:{"type":"text","name":"agency[address]","placeholder":"入力してください","maxlength":"256"},domProps:{"value":(_vm.agencyFormData.address)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.agencyFormData, "address", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_vm._v(" "),_c('span',{staticClass:"error-explanation"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_vm._v(" "),_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-xl-3"},[_vm._v("電話番号"),_c('required-mark')],1),_vm._v(" "),_c('div',{staticClass:"col-xl-9"},[_c('ValidationProvider',{attrs:{"name":"電話番号","rules":"required|numeric|min:10|max:11"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.agencyFormData.phone_number),expression:"agencyFormData.phone_number",modifiers:{"trim":true}}],staticClass:"form-control",attrs:{"type":"text","name":"agency[phone_number]","placeholder":"入力してください","maxlength":"12"},domProps:{"value":(_vm.agencyFormData.phone_number)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.agencyFormData, "phone_number", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_vm._v(" "),_c('span',{staticClass:"error-explanation"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_vm._v(" "),_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-xl-3"},[_vm._v("パスワード"),_c('required-mark')],1),_vm._v(" "),_c('div',{staticClass:"col-xl-9"},[_c('ValidationProvider',{attrs:{"name":"パスワード","rules":"required|min:8|max:128","vid":"password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.agencyFormData.password),expression:"agencyFormData.password",modifiers:{"trim":true}}],staticClass:"form-control",attrs:{"type":"text","name":"agency[password]","placeholder":"入力してください","maxlength":"256"},domProps:{"value":(_vm.agencyFormData.password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.agencyFormData, "password", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_vm._v(" "),_c('span',{staticClass:"error-explanation"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_vm._v(" "),_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-xl-3"},[_vm._v("パスワード（確認用）"),_c('required-mark')],1),_vm._v(" "),_c('div',{staticClass:"col-xl-9"},[_c('ValidationProvider',{attrs:{"name":"パスワード（確認用）","rules":"required|confirmed:password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.agencyFormData.password_confirmation),expression:"agencyFormData.password_confirmation",modifiers:{"trim":true}}],staticClass:"form-control",attrs:{"type":"text","name":"agency[password_confirmation]","placeholder":"入力してください","maxlength":"256"},domProps:{"value":(_vm.agencyFormData.password_confirmation)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.agencyFormData, "password_confirmation", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_vm._v(" "),_c('span',{staticClass:"error-explanation"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)])]),_vm._v(" "),_c('div',{staticClass:"card-footer row-form-btn d-flex"},[_c('div',{staticClass:"btn btn-info fw-120",attrs:{"disabled":invalid},on:{"click":function($event){validate().then(_vm.onSubmit)}}},[_vm._v("登録")])])]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }